export const parkingsOJP = [
  // {
  //   building: "2-3",
  //   hall: "H2",
  //   number: "12",
  //   file: "/assets/files/OJP/parkings/Hala_2-3_miejsce_12.pdf",
  // },
  // {
  //   building: "2-3",
  //   hall: "H2",
  //   number: "14",
  //   file: "/assets/files/OJP/parkings/Hala_2-3_miejsce_14.pdf",
  // },
  // {
  //   building: "2-3",
  //   hall: "H2",
  //   number: "16",
  //   file: "/assets/files/OJP/parkings/Hala_2-3_miejsce_16.pdf",
  // },
  // {
  //   building: "2-3",
  //   hall: "H2",
  //   number: "17",
  //   file: "/assets/files/OJP/parkings/H2_17.jpg",
  // },
  // {
  //   building: "2-3",
  //   hall: "H2",
  //   number: "19",
  //   file: "/assets/files/OJP/parkings/H2_19.jpg",
  // },
  {
    building: "2-3",
    hall: "H2",
    number: "21",
    file: "/assets/files/OJP/parkings/Hala_2-3_miejsce_21.pdf",
  },
  // {
  //   building: "2-3",
  //   hall: "H2",
  //   number: "25",
  //   file: "/assets/files/OJP/parkings/Hala_2-3_miejsce_25.pdf",
  // },
  // {
  //   building: "2-3",
  //   hall: "H2",
  //   number: "26",
  //   file: "/assets/files/OJP/parkings/Hala_2-3_miejsce_26.pdf",
  // },
  {
    building: "2-3",
    hall: "H2",
    number: "38",
    file: "/assets/files/OJP/parkings/Hala_2-3_miejsce_38.pdf",
  },
  {
    building: "2-3",
    hall: "H2",
    number: "40",
    file: "/assets/files/OJP/parkings/Hala_2-3_miejsce_40.pdf",
  },

  // {
  //   building: "4-5",
  //   hall: "H3",
  //   number: "3",
  //   file: "/assets/files/OJP/parkings/Hala_4-5_miejce_3.pdf",
  // },
  // {
  //   building: "4-5",
  //   hall: "H3",
  //   number: "12",
  //   file: "/assets/files/OJP/parkings/Hala_4-5_miejce_12.pdf",
  // },
  // {
  //   building: "4-5",
  //   hall: "H3",
  //   number: "15",
  //   file: "/assets/files/OJP/parkings/Hala_4-5_miejce_15.pdf",
  // },
  // {
  //   building: "4-5",
  //   hall: "H3",
  //   number: "19",
  //   file: "/assets/files/OJP/parkings/Hala_4-5_miejce_19.pdf",
  // },
  // {
  //   building: "4-5",
  //   hall: "H3",
  //   number: "38",
  //   file: "/assets/files/OJP/parkings/Hala_4-5_miejce_38.pdf",
  // },
  // {
  //   building: "4-5",
  //   hall: "H3",
  //   number: "40",
  //   file: "/assets/files/OJP/parkings/Hala_4-5_miejce_40.pdf",
  // },
  //
  // {
  //   building: "ZEWNĘTRZNE ",
  //   hall: "-",
  //   number: "10",
  //   file: '/assets/files/OJP/parkings/Miejsce_postojowe_zewnetrzne.pdf'
  // },
  // {
  //   building: "6-7",
  //   hall: "H4",
  //   number: "8",
  //   file: "/assets/files/OJP/parkings/H4_8.jpg",
  // },
  // {
  //   building: "6-7",
  //   hall: "H4",
  //   number: "26",
  //   file: "/assets/files/OJP/parkings/H4_26.jpg",
  // },
  // {
  //   building: "Zewnętrzne",
  //   hall: "-",
  //   number: "53",
  //   file: "",
  // },
]
